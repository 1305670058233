exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-category-page-js": () => import("./../../../src/templates/CategoryPage.js" /* webpackChunkName: "component---src-templates-category-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/IndexPage.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-post-page-js": () => import("./../../../src/templates/PostPage.js" /* webpackChunkName: "component---src-templates-post-page-js" */),
  "component---src-templates-reading-list-page-js": () => import("./../../../src/templates/ReadingListPage.js" /* webpackChunkName: "component---src-templates-reading-list-page-js" */),
  "component---src-templates-reading-lists-page-js": () => import("./../../../src/templates/ReadingListsPage.js" /* webpackChunkName: "component---src-templates-reading-lists-page-js" */),
  "component---src-templates-tag-page-js": () => import("./../../../src/templates/TagPage.js" /* webpackChunkName: "component---src-templates-tag-page-js" */)
}

