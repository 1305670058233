exports.onRouteUpdate = ({ location }) => {
  const canonicalEl = document.querySelector("link[rel='canonical']");
  const ogEl = document.querySelector("meta[property='og:url']");
  const existingValue = canonicalEl.getAttribute('href');
  const baseProtocol = canonicalEl.getAttribute('data-baseProtocol');
  const baseHost = canonicalEl.getAttribute('data-baseHost');
  const href = `${baseProtocol}//${baseHost}${location.pathname}`;

  if (existingValue && baseProtocol && baseHost) {
    canonicalEl.setAttribute('href', href);
    ogEl.setAttribute('content', href);
  }
};
