const {
  cid,
  analytics,
  GATracker,
} = require('@freeletics/web-package-tracking');

const { inhouseTracking } = require('@freeletics/web-package-inhouse-tracking');

// `document.documentElement.lang` is available after first page is loaded
// so we need workaround for initial page impression
const getLocaleFromPath = (path) => {
  const langInPathMatch = path.match(/^\/([a-z]{2})(?:\/|$)/);
  return langInPathMatch ? langInPathMatch[1] : 'en';
};

exports.onClientEntry = () => {
  /*
   * Campaign ID tracking
   */
  cid.configure({
    API_URL: process.env.GATSBY_BODYWEIGHT_API_URL,
    USER_API_URL: process.env.GATSBY_USER_API_URL,
  });

  if (!document.referrer.includes('freeletics.com')) {
    cid.track();
  }

  inhouseTracking.configure({
    TRACKING_API_URL: process.env.GATSBY_TRACKING_API_URL,
    IN_HOUSE_TRACKING_SALT: process.env.GATSBY_IN_HOUSE_TRACKING_SALT,
    IN_HOUSE_TRACKING_SECRET: process.env.GATSBY_IN_HOUSE_TRACKING_SECRET,
    APP_TYPE: 'fl-web-blog',
  });
};

exports.onRouteUpdate = () => {
  /*
   * Page view tracking via GTM
   */
  analytics.trackPageImpression();

  const campaignId = new URLSearchParams(window.location.search).get('CID');

  const locale =
    document.documentElement.lang ||
    getLocaleFromPath(window.location.pathname);
  new GATracker({ locale }).track({
    hit_type: 'page_impression',
    campaign_id: campaignId,
  });
};
